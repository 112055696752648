import styled from 'styled-components';
import { Button } from 'react-bootstrap';

const SubmitButton = styled(Button)`
  background: #004165;
  color: white;
  border: none;
  display: block;
  margin: 2rem auto;
  padding: 0.5em 1.5em;
  &:hover {
    background: rgb(0, 100, 202);
    color: white;
  }
`;

export default SubmitButton
