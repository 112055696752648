// @ts-nocheck
import React, { useState, useMemo } from 'react';
import queryString from 'query-string'
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { injectIntl } from 'gatsby-plugin-intl';
import axios from 'axios';
import { eachYearOfInterval, getYear, format, sub } from 'date-fns'
import countryList from 'react-select-country-list'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import 'animate.css/animate.min.css';
import { Row, Form, Col, Button } from 'react-bootstrap';

import cities from '../data/cities.json';
import Layout from '../components/Layout';
import DatePicker from '../components/DatePicker';
import SEO from '../components/Seo';
import Phone from '../components/Phone';
import Title from '../components/Title';
import SubmitButton from '../components/SubmitButton';
import heroImage from '../images/banner-3.jpg';
import Footer from '../components/Footer';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Heading = styled.h1`
  color: #004165;
  font-size: ${({ size }) => size ? `${size}em` : '2em'};
  font-weight: bold;
  margin-bottom: 1em;
`;

const ApplyForm = ({ location }) => {
  const yearDates = eachYearOfInterval({ start: new Date(1950, 1 ,1), end: new Date() })
  const countries = useMemo(() => countryList().getData(), [])
  const queryParams = queryString.parse(location.search)
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    gender: '',
    dateOfBirth: '',
    type: '',
    hostMentorCity1: '',
    hostMentorCity2: '',
    countryOfNationality: '',
    programDate: '',
    phone: '',
    programLength: '',
    programLengthOther: '',
    referralCode: '',
    message: '',
    _gotcha: '',
    _replyto: ''
  });

  const handleChange = event => {
    const { name } = event.target;
    const { value } = event.target;

    setFormValues({ ...formValues, [name]: value });
  };

  const handleDateChange = (name, value) => {
    if (value) {
      const date = format(value, 'MMMM do yyyy')
      setFormValues({ ...formValues, [name]: value });
    } else {
      setFormValues({ ...formValues, [name]: '' });
    }
  }

  const handleProgramLengthChange = event => {
    const { value } = event.target

    if (value === 'other' && formValues.programLengthOther === '') {
      setFormValues({ ...formValues, programLength: value, programLengthOther: '10' })
    } else if (formValues.programLengthOther !== '') {
      setFormValues({ ...formValues, programLength: value, programLengthOther: '' })
    } else {
      setFormValues({ ...formValues, programLength: value })
    }
  }

  const handleChangePhone = phone => {
    setFormValues({ ...formValues, phone });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post('https://formspree.io/f/mjjjlbdm', {
        ...formValues,
        contactAccepted: true,
        termsAndConditionsAccepted: true,
        affiliate: queryParams.affiliate || 'N/A',
        privacyPolicyAccepted: true,
        _cc: 'info@homestaynetwork.org',
        _subject: 'New Virtual Homestay ENGLISH Application'
      })
      .then(() => {
        setFormValues({
          firstName: '',
          lastName: '',
          gender: '',
          dateOfBirth: '',
          type: '',
          hostMentorCity1: '',
          hostMentorCity2: '',
          countryOfNationality: '',
          programMonth: '',
          programYear: '',
          phone: '',
          programLength: '',
          programLengthOther: '',
          referralCode: '',
          message: '',
          _gotcha: '',
          _replyto: ''
        });
        toast.success('Thank you. Your application was submitted successfully.');
      });
  };

  return (
    <>
      <Helmet />
      <Layout>
        <SEO title="Apply Now" />
        <Wrapper className="container-fluid">
          <Title imageUrl={heroImage} position="top" text="Apply Now" centered />
          <Row className="pt-5">
            <Col lg={5} md={8} sm={12} className="mx-auto">
              <Heading>
                Complete the form below to apply for Virtual Homestay ENGLISH.
              </Heading>
              <Heading as="h2" size={1.5}>
                We will email you with further information about payment options and the next steps to finalise your application.
              </Heading>
              <p>All fields marked with an asterisk (*) are mandatory.</p>
              <Form onSubmit={(e) => handleSubmit(e)}>
                <Form.Control
                  type="text"
                  name="_gotcha"
                  style={{ display: 'none' }}
                  value={formValues._gotcha}
                  onChange={handleChange}
                />
                <Form.Row>
                  <Form.Group as={Col} md={6} sm={12}>
                    <Form.Label>First Name *</Form.Label>
                    <Form.Control
                      id="firstName"
                      name="firstName"
                      onChange={handleChange}
                      value={formValues.firstName}
                      type="text"
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={6} sm={12}>
                    <Form.Label>Last Name *</Form.Label>
                    <Form.Control
                      id="lastName"
                      name="lastName"
                      onChange={handleChange}
                      value={formValues.lastName}
                      type="text"
                      required
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md={6} sm={12}>
                    <Form.Label>Gender *</Form.Label>
                    <Form.Control
                      as="select"
                      id="gender"
                      name="gender"
                      onChange={handleChange}
                      value={formValues.gender}
                      required
                    >
                      <option value=""></option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} md={6} sm={12}>
                    <Form.Label>Date of Birth *</Form.Label>
                    <Form.Row>
                      <Form.Group as={Col}>
                        <DatePicker
                          onDateChange={date => handleDateChange('dateOfBirth', date)}
                          maxDate={sub(new Date(), { years: 12 })}
                        />
                      </Form.Group>
                    </Form.Row>
                  </Form.Group>
                  
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md={6} sm={12}>
                    <Form.Label>Country of Nationality *</Form.Label>
                    <Form.Control
                      as="select"
                      id="countryOfNationality"
                      name="countryOfNationality"
                      onChange={handleChange}
                      value={formValues.countryOfNationality}
                      required
                    >
                      <option value=""></option>
                      {countries.map(country => (
                        <option value={country.label}>{country.label}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} md={6} sm={12}>
                    <Form.Label>Current Level of English *</Form.Label>
                    <Form.Control
                      as="select"
                      id="type"
                      name="type"
                      onChange={handleChange}
                      value={formValues.type}
                      required
                    >
                      <option value=""></option>
                      <option value="beginner">Beginner</option>
                      <option value="early-intermediate">Early Intermediate</option>
                      <option value="intermediate">Intermediate</option>
                      <option value="advanced">Advanced</option>
                      <option value="fluent">Fluent</option>
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md={6} sm={12}>
                    <Form.Label>Email *</Form.Label>
                    <Form.Control
                      id="contactEmail"
                      name="_replyto"
                      onChange={handleChange}
                      value={formValues._replyto}
                      type="email"
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={6} sm={12}>
                    <Form.Label>Phone *</Form.Label>
                    <Phone
                      onChange={handleChangePhone}
                      value={formValues.phone}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Label>Did you want to connect with a host mentor from a particular city in Australia? Select up to 2 options. *</Form.Label>
                <Form.Row>
                  <Form.Group as={Col} md={6} sm={12}>
                    {!formValues.hostMentorCity1 && (
                      <span className="placeholder">City 1 (Required)</span>
                    )}
                    <Form.Control
                      as="select"
                      id="hostMentorCity1"
                      name="hostMentorCity1"
                      onChange={handleChange}
                      value={formValues.hostMentorCity1}
                      required
                    >
                      <option value="" selected disabled hidden />
                      {cities.map(city => (
                        <option value={city}>{city}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} md={6} sm={12}>
                    {!formValues.hostMentorCity2 && (
                      <span className="placeholder">City 2 (Optional)</span>
                    )}
                    <Form.Control
                      as="select"
                      id="hostMentorCity2"
                      name="hostMentorCity2"
                      onChange={handleChange}
                      value={formValues.hostMentorCity2}
                    >
                      <option value="" selected disabled hidden />
                      {cities.map(city => (
                        <option value={city}>{city}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
                <Form.Label>
                  When would you like to begin the program? *
                  <br/>
                  <small>NOTE: We recommend you apply at least 4 weeks before you intend on commencing the program in order for us to match you with a suitable host mentor.</small>
                </Form.Label>
                <Form.Row>
                  <Form.Group as={Col}>
                    <DatePicker
                      onDateChange={date => handleDateChange('programDate', date)}
                      minDate={new Date()}
                      maxDetail="month"
                      minDetail="year"
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>
                      What is your preferred program length? *
                      <br/>
                      <small>NOTE: The minimum is 4 weeks with our Starter Package. You can add Extension Package sessions, which will extend the program by 2 weeks each time you purchase the package.</small>
                    </Form.Label>
                    <div key="inline-radio">
                      <Form.Check inline label="4 Weeks (Minimum program duration)" onChange={handleProgramLengthChange} value="4 Weeks" name="programLength" type="radio" id="program-length-1" />
                      <Form.Check inline label="6 Weeks" name="programLength" onChange={handleProgramLengthChange} value="6 Weeks" type="radio" id="program-length-2" />
                      <Form.Check inline label="8 Weeks" name="programLength" onChange={handleProgramLengthChange} value="8 Weeks" type="radio" id="program-length-3" />
                      <Form.Check inline label="Other (Enter total number of weeks)" onChange={handleProgramLengthChange} value="other" name="programLength" type="radio" id="program-length-4" />
                    </div>
                    {formValues.programLength === 'other' && (
                      <Form.Control
                        name="programLengthOther"
                        type="number"
                        value={formValues.programLengthOther}
                        onChange={handleChange}
                        placeholder="Enter total number of weeks"
                        min={10}
                        max={52}
                        step={2}
                        className="mt-2"
                      />
                    )}
                  </Form.Group>
                </Form.Row>
                <Form.Group>
                  <Form.Label>Do you have a referral code? Enter it here.</Form.Label>
                  <Form.Control
                    id="referralCode"
                    name="referralCode"
                    onChange={handleChange}
                    value={formValues.referralCode}
                    type="text"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Message (optional)</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="4"
                    name="message"
                    value={formValues.message}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Check
                    type="checkbox"
                    label={(
                      <>I have read and agree with the Virtual Homestay ENGLISH <a href="/en/terms-and-conditions">Terms & Conditions</a> which can be found at the footer of the page. *</>
                    )}
                    required
                  />
                  <Form.Check
                    type="checkbox"
                    label={(
                      <>I agree with the MyStay English <a href="https://mystayenglish.com/en/privacy-policy">privacy policy</a> which can be found at the footer of the page. *</>
                    )}
                    required
                  />
                  <Form.Check
                    type="checkbox"
                    label="By submitting this form I agree to receive information about the Virtual Homestay ENGLISH program. *"
                    required
                  />
                </Form.Group>
                <SubmitButton variant="primary" type="submit">
                  Submit
                </SubmitButton>
              </Form>
            </Col>
          </Row>
          <Footer />
        </Wrapper>
      </Layout>
      <ToastContainer />
    </>
  );
};

export default injectIntl(ApplyForm);
