import React, { useState, useEffect } from 'react'
import { add } from 'date-fns'
import ReactDatePicker from 'react-date-picker'
import './styles/DatePicker.css'

const DatePicker = ({ onDateChange, maxDetail, minDetail, minDate, maxDate, defaultDate }) => {
  const [date, setDate] = useState(defaultDate)

  const onChange = newDate => {
    setDate(newDate)
    onDateChange(newDate)
  }

  useEffect(() => {
    if (!defaultDate) return

    onDateChange(defaultDate)
  }, [])

  return (
    <div>
      <ReactDatePicker
        onChange={onChange}
        maxDetail={maxDetail}
        minDetail={minDetail}
        dayPlaceholder="DD"
        monthPlaceholder="MM"
        yearPlaceholder="YYYY"
        format="dd/MM/yyyy"
        maxDate={maxDate}
        minDate={minDate}
        defaultActiveStartDate={maxDate}
        next2Label={null}
        prev2Label={null}
        value={date}
      />
    </div>
  )
}

export default DatePicker

