import React from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'

const Phone = ({ value, onChange }) => (
  <PhoneInput
    country="us"
    value={value}
    onChange={phone => onChange(phone)}
  />
)

export default Phone
